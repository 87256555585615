module.exports = [{
      plugin: require('/Users/alexander/WebstormProjects/blockvis-com/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N7HXW5S","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('/Users/alexander/WebstormProjects/blockvis-com/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/shared/favicon.png"},
    },{
      plugin: require('/Users/alexander/WebstormProjects/blockvis-com/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/alexander/WebstormProjects/blockvis-com/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('/Users/alexander/WebstormProjects/blockvis-com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
